import React, { FC } from "react";
import { Control, useController, useFormContext } from "react-hook-form";
import classNames from "classnames";

const styleSizes = {
  regular: {
    container: "h-6 w-12",
    roundedItem: "w-6 h-6",
    activePadding: "left-6",
  },
  small: {
    container: "h-4 w-8",
    roundedItem: "w-4 h-4",
    activePadding: "left-4",
  },
  smaller: {
    container: "h-3 w-6",
    roundedItem: "w-3 h-3",
    activePadding: "left-3",
  },
};

interface ToggleProps {
  className?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  size?: keyof typeof styleSizes;
  disabled?: boolean;
}

export const Toggle: FC<ToggleProps> = ({
  className,
  value,
  onChange,
  size = "regular",
  disabled = false,
}) => {
  return (
    <label
      className={classNames(
        `ring-2 rounded-3xl transition-all relative cursor-pointer shrink-0 block`,
        !value || disabled
          ? "bg-grey-900 ring-grey-900"
          : "bg-green-online-600 ring-green-online-600 ",
        styleSizes[size].container,
        className
      )}
    >
      <input
        type="checkbox"
        value={value === true ? "true" : "false"}
        checked={value}
        onChange={() => onChange(!value)}
        className="absolute opacity-0 w-0 h-0 focus:ring-0"
        disabled={disabled}
      />
      <div
        className={classNames(
          `absolute rounded-3xl bg-white transition-all shadow`,
          styleSizes[size].roundedItem,
          value && styleSizes[size].activePadding
        )}
      />
    </label>
  );
};

interface ToggleFormComponentProps {
  name: string;
  size: keyof typeof styleSizes;
  control?: Control;
  disabled?: boolean;
}

export const ToggleFormComponent: FC<ToggleFormComponentProps> = ({
  name,
  size = "regular",
  control,
  disabled = false,
}) => {
  const formContext = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control: formContext?.control || control,
    shouldUnregister: true,
  });

  return (
    <Toggle size={size} onChange={onChange} value={value} disabled={disabled} />
  );
};

export default Toggle;
