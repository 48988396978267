import _ from "lodash";
import { useDocument } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { ClientType } from "@lib/data/schemas/client";

type UseContactType = (
  contactId: string | undefined,
  coachUserId?: string
) => {
  contact?: ClientType | null;
  loading: boolean;
};

const useContact: UseContactType = (contactId, coachUserId = undefined) => {
  const { uid } = useAuth();
  const { data } = useDocument<ClientType>(
    contactId ? `users/${coachUserId || uid}/clients/${contactId}` : null,
    { listen: true }
  );

  if (data?.address && _.isString(data.address)) {
    data.address = {
      city: "",
      country: "",
      line1: data.address,
      postalCode: "",
      state: "",
    };
  }

  return { contact: data?.exists ? data : undefined, loading: !data };
};

export default useContact;

type UseUserClientType = (
  userId: string | undefined,
  clientId: string | undefined
) => {
  client?: ClientType | null;
  loading: boolean;
};

export const useUserClient: UseUserClientType = (userId, clientId) => {
  const { data, loading } = useDocument<ClientType>(
    clientId && userId ? `users/${userId}/clients/${clientId}` : null
  );

  return { client: data, loading };
};
