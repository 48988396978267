import { useCollection } from "swr-firebase";

import { LabelType } from "@lib/shared-types";

export default function useLabels(userId: string) {
  const { data: labels = [], add } = useCollection<LabelType>(
    userId ? `users/${userId}/labels` : null,
    {
      where: ["status", "==", "active"],
      listen: true,
    }
  );

  return {
    labels,
    loading: !labels,
    add,
  };
}
